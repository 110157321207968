import Logo from '../../../assets/img/common/logo.png';
import { Row, Col } from 'reactstrap';
import { Link } from "gatsby";
import React from "react";

import './index.scss';

const Header = () => {
  return (
    <Row className="header">
      <Col md={12} className="d-flex justify-content-left">
        <Link
          to="/"
        >
          <img src={Logo} alt="Labotana" />
        </Link>
      </Col>
    </Row>
  )
}

export default Header;

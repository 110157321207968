import { Row, Col } from 'reactstrap';
import React from 'react';

const ContactContent = () => {
  return (
    <Row className="white-bg pt-3 pb-3">
      <Col md={8} className="d-flex">
        <h6>Contacto: <a href="mailto:labotana@gmail.com">labotana@gmail.com</a></h6>
      </Col>
    </Row>
  );
}

export default ContactContent;

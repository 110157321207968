import { Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import React from "react";

import './index.scss';

const Footer = () => {
  return (
    <Row className="footer">
      <Col md={12}>
        <ul>
          <li>
            <Link
              to="/politica-de-privacidad"
            >
              Politica de Privacidad
            </Link>
          </li>
          <li>
            <Link
              to="/quienes-somos"
            >
              Quienes Somos
            </Link>
          </li>
        </ul>
      </Col>
      <Col md={12} className="d-flex justify-content-start align-items-center copyright">
        <p className="copyright-text">Copyright 1999-{new Date().getFullYear()}</p>
      </Col>
    </Row>
  );
}

export default Footer;

import { Row, Col } from "reactstrap";
import { Link } from 'gatsby';
import React from "react";

import './index.scss';

const Menu = () => {
  return (
    <Row className="menu">
      <Col>
        <ul>
          <li>
            <Link
              to="/"
            >
              INICIO
            </Link>
          </li>
          <li>
            <Link
              to="/category/fotogalerias"
            >
              FOTOGALERIAS
            </Link>
          </li>
          <li>
            <Link
              to="/contacto"
            >
              TU MARCA AQUÍ/CONTACTO
            </Link>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default Menu;
